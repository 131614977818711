
import Controller from './controller';

const Modal = {

	init: function () {

		this.view = {};

		this.scrollY = null;

		$(document).on('click', '.open-modal', this.openModal.bind(this));

		$(document).on('click', '.close-modal', this.closeModal.bind(this));
	},

	openModal: function (el) {

		var modalName = $(el.currentTarget).data('modal');
		var element = document.getElementsByClassName(modalName);
		this.scrollY = Controller.view.window.y;

		$('body').css({
			position: 'fixed',
			width: '100%',
			left: '0',
			top: -1 * this.scrollY,
		});
		$(element).addClass('active');

	},
	closeModal: function (el) {

		var target = $(el.currentTarget).parents('.g-modal-frame');
		var top = $('body').css('top');
		top = Math.abs(parseInt(top));
		$('body').removeAttr('style');
		$('body, html').prop({ scrollTop: top });
		$(target).removeClass('active');
	},
}

export default Modal;
