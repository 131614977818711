import Controller from './controller';

var Header = {

	init: function(){
		$('.header .toggle').on('click', this.toggle.bind(this));
		$('.header').on({
			'mouseenter': function() {

				$('.header').addClass('is-hover');

			},
			'mouseleave': function() {

				$('.header').removeClass('is-hover');

			}
		})
	},

	toggle: function(el) {
		if(Controller.device == 'SP') {
			$('.header').toggleClass('is-active');
		}

	}

}

export default Header;
