
const $ = window.$ = require('jquery');

import Controller from './modules/controller';
import Detail from './modules/detail';
import Header from './modules/header';
import List from './modules/list';
import Modal from './modules/modal';
import Opening from './modules/opening';
import Youtube from './modules/youtube'

(function($){

	Controller.standby();
	Modal.init();
	Youtube.init();

	Header.init();
	Detail.init();
	Opening.init();
	List.init();

})(jQuery)
