
var Opening = {

	init: function() {
		$(window).on('load', this.anime.bind(this));
	},
	anime: function() {
		$('.opening').addClass('is-active');
		setTimeout(function() {
			$('.opening').remove('.opening')
			$('.site-frame').addClass('active');
		}, 3000)
	}
}

export default Opening;
