import Controller from './controller';

var List = {

	init: function() {

		if(!$('.list-page').get(0)) {
			return
		}

		$(window).on('load', this.loadSelect.bind(this));

		$('.model-list-item .link').on('click', this.hoverSP.bind(this));
		$('.select-list-item').on('click', this.sexToggle.bind(this));
	},
	loadSelect: function() {
		// URLを取得
		var url = new URL(window.location.href);

		// URLSearchParamsオブジェクトを取得
		var params = url.searchParams;
		var sex = params.get('sex');
		if(sex != null) {
			var target = document.getElementsByClassName(sex);

			$('.model-list').removeClass('is-active')
			$(target).addClass('is-active');

			$('.select-list-item').removeClass('is-active');
			$('.select-list-item[data-sex="'+ sex +'"]').addClass('is-active');

		} else {
			$('.women').addClass('is-active');
			$('.select-list-item[data-sex="women"]').addClass('is-active');
		}

	},
	hoverSP: function(el) {
		if(Controller.device === 'SP') {
			if(!$(el.currentTarget).hasClass('is-hover')) {
				$('.model-list-item .link').removeClass('is-hover');
				$(el.currentTarget).addClass('is-hover');
				return false
			}

		}
	},
	sexToggle: function(el) {
		$('.select-list-item').removeClass('is-active');
		$(el.currentTarget).addClass('is-active');

		var sex = $(el.currentTarget).data('sex');
		var target = document.getElementsByClassName(sex);
		$('.model-list').removeClass('is-active')
		$(target).addClass('is-active');
	}

}

export default List;
