import Controller from './controller';

 // import Swiper JS
 import Swiper from 'swiper';
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination]);

var Detail = {

	init: function() {
		if(!$('.detail-page').get(0)) {
			return
		}
		// $('.book .swiper-item').each(function(index){
		// 	var html = null;
		// 	if($(this).hasClass('image')) {
		// 		var src = $(this).css("background-image").replace(/^url|[\(\)]/g, '');
		// 		html = '<div class="swiper-slide"><div class="img-wrap"><img src=' + src + '></div></div>';
		// 	}
		// 	else {
		// 		var id = $(this).data('youtube');
		// 		html = '<div class="swiper-slide"><div class="wrap"><iframe type="text/html" width="920" height="520" src="https://www.youtube.com/embed/'+ id +'"frameborder="0"></iframe></div></div>';
		// 	}

		// 	$('.modal-book .swiper-wrapper').append(html)
		// });
		// this.bookSwiper = new Swiper('.modal-book .swiper-container', {
		// 	navigation: {
		// 		nextEl: '.modal-book .swiper-button-next',
		// 		prevEl: '.modal-book .swiper-button-prev',
		// 	},
		// });

		// $('.polaroid .swiper-item').each(function(index){
		// 	var html = null;
		// 	if($(this).hasClass('image')) {
		// 		var src = $(this).css("background-image").replace(/^url|[\(\)]/g, '');
		// 		html = '<div class="swiper-slide"><div class="img-wrap"><img src=' + src + '></div></div>';
		// 	}
		// 	else {
		// 		var id = $(this).data('youtube');
		// 		html = '<div class="swiper-slide"><div class="wrap"><iframe type="text/html" width="920" height="520" src="https://www.youtube.com/embed/'+ id +'"frameborder="0"></iframe></div></div>';
		// 	}

		// 	$('.modal-polaroid .swiper-wrapper').append(html)
		// });
		// this.polaroidSwiper = new Swiper('.modal-polaroid .swiper-container', {
		// 	navigation: {
		// 		nextEl: '.modal-polaroid .swiper-button-next',
		// 		prevEl: '.modal-polaroid .swiper-button-prev',
		// 	},
		// });

		// $('.video .swiper-item').each(function(index){
		// 	var html = null;
		// 	if($(this).hasClass('image')) {
		// 		var src = $(this).css("background-image").replace(/^url|[\(\)]/g, '');
		// 		html = '<div class="swiper-slide"><div class="img-wrap"><img src=' + src + '></div></div>';
		// 	}
		// 	else {
		// 		var id = $(this).data('youtube');
		// 		html = '<div class="swiper-slide"></div></div>';
		// 	}

		// 	$('.modal-video .swiper-wrapper').append(html)
		// });
		// this.videoSwiper = new Swiper('.modal-video .swiper-container', {
		// 	navigation: {
		// 		nextEl: '.modal-video .swiper-button-next',
		// 		prevEl: '.modal-video .swiper-button-prev',
		// 	},
		// });

		// $('.swiper-item').on('click', this.changeSlide.bind(this));

		$('.anchor-list-item').on('click', this.anchor.bind(this));
		$('.anchor-list-item').on('click', this.switchColumn.bind(this));
	},
	// changeSlide: function(el) {
	// 	var index = $(el.currentTarget).data('index');
	// 	var className = $(el.currentTarget).data("modal");
	// 	var modal = document.getElementsByClassName(className);
	// 	console.log()
	// 	if($(modal).hasClass('modal-book')) {
	// 		console.log('a')
	// 		this.bookSwiper.slideTo(index);
	// 	}
	// 	else if($(modal).hasClass('modal-polaroid')) {
	// 		this.polaroidSwiper.slideTo(index);
	// 	}
	// 	else {
	// 		console.log('c')
	// 		this.videoSwiper.slideTo(index);
	// 	}

	// },
	anchor: function(el) {

		if(Controller.device === 'PC') {
			$('.anchor-list-item').removeClass('is-active');
			$(el.currentTarget).addClass('is-active');

			var className = $(el.currentTarget).data('anchor');
			var element = document.getElementsByClassName(className);

			var offset = $(element).offset().top;
			$('body,html').animate({scrollTop: offset}, 300,'swing');
		}
	},
	switchColumn:function(el) {
		if(Controller.device === 'SP') {
			$('.anchor-list-item').removeClass('is-active');
			$(el.currentTarget).addClass('is-active');

			var className = $(el.currentTarget).data('anchor');
			var element = document.getElementsByClassName(className);

			$('.column').removeClass('is-active');
			$(element).addClass('is-active');

		}
	}
}

export default Detail;
